import styled from 'styled-components'

const Button = styled.button`
  animation: SpeedyGradient 3s ease infinite;
  background: #07588A;
  border: 0px;
  border-radius: 5px;
  color: #fff;
  padding: .5em 1em;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .25);
    span {
    background: linear-gradient(to right, #07588A, #39CCCC);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-user-select: none;
    user-select: none;
  }

  &:hover {
    animation: SpeedyGradient 3s ease infinite;
  background: linear-gradient(to right, #07588A, #39CCCC);
    transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

    span {
      color: #fff;
      -webkit-text-fill-color: #fff;
    }
  }
  @keyframes SpeedyGradient {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
  }
`

export default Button
