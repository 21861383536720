import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Button from './Button'
import styled from 'styled-components'
import { Dialog } from '@reach/dialog'
// import '@reach/dialog/styles.css';

const LightboxContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 5px;
`

const PreviewButton = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
`

export default class Lightbox extends Component {
  static propTypes = {
    images: PropTypes.array.isRequired, // eslint-disable-line
  }

  constructor(props) {
    super(props)

    this.state = {
      showLightbox: false,
      selectedImage: null,
    }
  }

  render() {
    const { images } = this.props

    // const litImages = images.map(x => ({x}))

    const { selectedImage, showLightbox } = this.state
    return (
      <Fragment>
        <LightboxContainer>
          {images.map(image => (
            <PreviewButton
              key={image.node.publicURL}
              type="button"
              onClick={() =>
                this.setState({ showLightbox: true, selectedImage: image })
              }
            >
              <img 
                src={image.node.publicURL} 
                alt={image.node.name}
              />
            </PreviewButton>
          ))}
        </LightboxContainer>
        {showLightbox && (
          <Dialog onClick={() => this.setState({ showLightbox: false })}>
            <img 
              src={selectedImage.node.publicURL} 
              alt={selectedImage.node.name}
            />
            <Button
              style={{ marginTop: '1em' }}
              type="button"
              onClick={() => this.setState({ showLightbox: false })}
            >
              Close
            </Button>
          </Dialog>
        )}
      </Fragment>
    )
  }
}
