import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Lightbox from './Lightbox'

const AlairConfImagesEighteen = () => (
  <StaticQuery
    query={graphql`
      query {
        confEighteenImages: allFile(
          filter: { sourceInstanceName: { eq: "confEighteenImages" } }
        ) {
          edges {
            node {
              publicURL
              name
            }
          }
        }
      }
    `}
    render={data => <Lightbox images={data.confEighteenImages.edges} />}
  />
)
export default AlairConfImagesEighteen
