import React from 'react'
import AlairConfImagesEighteen from '../../components/AlairConfImagesEighteen'
import H1 from '../../components/H1'
import Layout from '../../components/Layout'

const acp2018 = () => (
  <Layout>
    <H1 title="ALAIR Conference Photos 2018" />
    <AlairConfImagesEighteen />
  </Layout>
)
export default acp2018
